import React from "react"
import Layout from "../../components/layout"

import newsPostStyles from "../../styles/templates/news-post.module.css"

import babaeAtBaril from "../../images/news/article-1/babae-at-baril-scene.jpg"
import cutout from "../../images/news/article-1/cutout.png"
import editing  from "../../images/news/article-1/editing.png"
import poster from "../../images/news/article-1/poster.jpg"

const Article1Page = () => {
    return (
        <Layout location="news">
        <div className={ newsPostStyles.article }>
            <h1>SPECIAL TANGLAW AWARD</h1>
            <h3>November 26, 2020</h3>
            <div className={ newsPostStyles.imageRow }>
                <img src={poster} alt=""/>
                <img src={editing} alt=""/>
                <img src={babaeAtBaril} alt=""/>
            </div>
            <p>Congratulations Babae at Baril for winning the Best Picture, Best Director, Best Cinematography, Best Actress and Best Production Design in Gawad Urian awards!</p>
            <p>Brimming with relevant insights about women empowerment and patriarchal system in Filipino society, the film also crafted carefully through technical excellency that brings a remarkable aspect in the film.</p>
            <p className={ newsPostStyles.signOff}>-Creative Caboose Post Team</p>
            <div className={ newsPostStyles.landscape }>
                <img src={cutout} alt=""/>
            </div>
            <button onClick={() => {
                window.history.back()
            }}>Go Back</button>
        </div>
        </Layout>
    )
}

export default Article1Page;